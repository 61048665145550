import { createVNode as _createVNode } from "vue";
export var FIconChevronRight = function FIconChevronRight() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "24",
    "height": "24",
    "viewBox": "0 0 24 24",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M8.46967 4.46967C8.17678 4.76256 8.17678 5.23744 8.46967 5.53033L14.9393 12L8.46967 18.4697C8.17678 18.7626 8.17678 19.2374 8.46967 19.5303C8.76256 19.8232 9.23744 19.8232 9.53033 19.5303L16.5303 12.5303C16.8232 12.2374 16.8232 11.7626 16.5303 11.4697L9.53033 4.46967C9.23744 4.17678 8.76256 4.17678 8.46967 4.46967Z",
    "fill": "currentColor"
  }, null)]);
};