import { createVNode as _createVNode } from "vue";
export var FIconConnect3PFill = function FIconConnect3PFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM5.26586 2.85001H4.49961C2.75991 2.85001 1.34961 4.26031 1.34961 6.00001C1.34961 7.7397 2.75991 9.15001 4.49961 9.15001H5.26586V7.85001H4.49961C3.47788 7.85001 2.64961 7.02173 2.64961 6.00001C2.64961 4.97828 3.47788 4.15001 4.49961 4.15001H5.26586V2.85001ZM6.76586 7.85001H7.49961C8.52134 7.85001 9.34961 7.02173 9.34961 6.00001C9.34961 4.97828 8.52134 4.15001 7.49961 4.15001H6.76586V2.85001H7.49961C9.23931 2.85001 10.6496 4.26031 10.6496 6.00001C10.6496 7.7397 9.23931 9.15001 7.49961 9.15001H6.76586V7.85001ZM5 5.35018C4.64102 5.35018 4.35 5.6412 4.35 6.00018C4.35 6.35917 4.64102 6.65018 5 6.65018H7C7.35898 6.65018 7.65 6.35917 7.65 6.00018C7.65 5.6412 7.35898 5.35018 7 5.35018H5Z",
    "fill": "currentColor"
  }, null)]);
};