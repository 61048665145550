import { createVNode as _createVNode } from "vue";
export var FIconSearch = function FIconSearch() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "24",
    "height": "24",
    "viewBox": "0 0 24 24",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M11 4.25C7.27208 4.25 4.25 7.27208 4.25 11C4.25 14.7279 7.27208 17.75 11 17.75C12.5938 17.75 14.0585 17.1976 15.2133 16.2739L18.0751 19.1357C18.368 19.4286 18.8429 19.4286 19.1357 19.1358C19.4286 18.8429 19.4286 18.368 19.1358 18.0751L16.2739 15.2132C17.1977 14.0585 17.75 12.5937 17.75 11C17.75 7.27208 14.7279 4.25 11 4.25ZM5.75 11C5.75 8.10051 8.10051 5.75 11 5.75C13.8995 5.75 16.25 8.10051 16.25 11C16.25 13.8995 13.8995 16.25 11 16.25C8.10051 16.25 5.75 13.8995 5.75 11Z",
    "fill": "currentColor"
  }, null)]);
};