import { createVNode as _createVNode } from "vue";
export var FIconArrowDown4PBold = function FIconArrowDown4PBold() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M9.00045 3C9.00045 2.44772 8.55274 2 8.00045 2C7.44817 2 7.00045 2.44772 7.00045 3V10.5858L4.46492 8.05025C4.07439 7.65973 3.44123 7.65973 3.05071 8.05025C2.66018 8.44078 2.66018 9.07394 3.05071 9.46447L7.29335 13.7071C7.68387 14.0976 8.31704 14.0976 8.70756 13.7071L12.9502 9.46447C13.3407 9.07394 13.3407 8.44078 12.9502 8.05025C12.5597 7.65973 11.9265 7.65973 11.536 8.05025L9.00045 10.5858V3Z",
    "fill": "currentColor"
  }, null)]);
};