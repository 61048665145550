import { createVNode, nextTick, render, getCurrentInstance } from "vue";
import { genPaymentUrl } from "@foxone/utils/mixin";
import { FPaymentModal } from "../components/FPaymentModal";
export function usePayment() {
  const instance = getCurrentInstance();
  return instance.appContext.config.globalProperties.$uikit.payment;
}
function install(app) {
  let instance = null;
  const show = options => {
    if (instance) {
      instance.component.exposed.show(options);
    }
    nextTick(() => {
      const appendTo = document.querySelector("[data-v-app]");
      const container = document.createElement("div");
      const vnode = createVNode(FPaymentModal, {
        ...options,
        attach: container,
        onDestroy: () => {
          render(null, container);
          instance = null;
          appendTo?.removeChild(container);
        }
      });
      vnode.appContext = app._context;
      render(vnode, container);
      appendTo?.appendChild(container);
      instance = vnode;
      instance.component.exposed.show(options);
    });
  };
  const mixin = options => {
    const scheme = genPaymentUrl(options);
    return show({
      ...options,
      channel: "mixin",
      scheme,
      actions: {
        mixin: () => window.location.href = scheme
      }
    });
  };
  const payment = {
    show,
    mixin
  };
  const properties = app.config.globalProperties;
  properties.$uikit = properties.$uikit || {};
  properties.$uikit.payment = payment;
}
export function Payment() {}
Payment.install = install;