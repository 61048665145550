import { createVNode as _createVNode } from "vue";
export var FIconUpRight3PFill = function FIconUpRight3PFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM7.24986 5.81078V7.42857C7.24986 7.84278 7.58565 8.17857 7.99986 8.17857C8.41407 8.17857 8.74986 7.84278 8.74986 7.42857V4C8.74986 3.58579 8.41407 3.25 7.99986 3.25H4.57129C4.15708 3.25 3.82129 3.58579 3.82129 4C3.82129 4.41421 4.15708 4.75 4.57129 4.75H6.18932L3.46967 7.46965C3.17678 7.76255 3.17678 8.23742 3.46967 8.53031C3.76256 8.82321 4.23744 8.82321 4.53033 8.53031L7.24986 5.81078Z",
    "fill": "currentColor"
  }, null)]);
};