import { createVNode as _createVNode } from "vue";
export var FIconLink4PBold = function FIconLink4PBold() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M13.2068 2.79276C11.2975 0.883411 8.2018 0.883411 6.29246 2.79276L5.64288 3.44233C5.25236 3.83286 5.25236 4.46602 5.64288 4.85655C6.03341 5.24707 6.66657 5.24707 7.0571 4.85655L7.70667 4.20697C8.83497 3.07867 10.6643 3.07867 11.7926 4.20697C12.9209 5.33527 12.9209 7.16461 11.7926 8.29291L11.1425 8.94304C10.7519 9.33356 10.7519 9.96673 11.1425 10.3573C11.533 10.7478 12.1662 10.7478 12.5567 10.3573L13.2068 9.70712C15.1162 7.79777 15.1162 4.70211 13.2068 2.79276Z",
    "fill": "currentColor"
  }, null), _createVNode("path", {
    "d": "M4.85726 7.05638C5.24779 6.66586 5.24779 6.03269 4.85726 5.64217C4.46674 5.25164 3.83357 5.25164 3.44305 5.64217L2.79236 6.29285C0.883015 8.2022 0.883015 11.2979 2.79236 13.2072C4.70171 15.1166 7.79738 15.1166 9.70672 13.2072L10.3571 12.5568C10.7477 12.1663 10.7477 11.5331 10.3571 11.1426C9.9666 10.7521 9.33344 10.7521 8.94291 11.1426L8.29251 11.793C7.16421 12.9213 5.33488 12.9213 4.20658 11.793C3.07828 10.6647 3.07828 8.83536 4.20658 7.70707L4.85726 7.05638Z",
    "fill": "currentColor"
  }, null), _createVNode("path", {
    "d": "M9.20674 8.2071C9.59727 7.81658 9.59727 7.18341 9.20674 6.79289C8.81622 6.40236 8.18305 6.40236 7.79253 6.79289L6.73187 7.85355C6.34134 8.24407 6.34134 8.87724 6.73187 9.26776C7.12239 9.65829 7.75556 9.65829 8.14608 9.26776L9.20674 8.2071Z",
    "fill": "currentColor"
  }, null)]);
};