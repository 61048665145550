import { createVNode as _createVNode } from "vue";
export var FIconChevronDown4P = function FIconChevronDown4P() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M13.5303 5.46967C13.2374 5.17678 12.7626 5.17678 12.4697 5.46967L8 9.93934L3.53033 5.46967C3.23744 5.17678 2.76256 5.17678 2.46967 5.46967C2.17678 5.76256 2.17678 6.23744 2.46967 6.53033L7.46967 11.5303C7.76256 11.8232 8.23744 11.8232 8.53033 11.5303L13.5303 6.53033C13.8232 6.23744 13.8232 5.76256 13.5303 5.46967Z",
    "fill": "currentColor"
  }, null)]);
};