import { isVNode as _isVNode, createVNode as _createVNode } from "vue";
import { defineComponent } from "vue";
import { useDisplay, useLocale } from "vuetify";
// import { getBrowser } from "../../utils";
import { FButton } from "../FButton";
function _isSlot(s) {
  return typeof s === 'function' || Object.prototype.toString.call(s) === '[object Object]' && !_isVNode(s);
}
export const FAuthFennecInstall = defineComponent({
  name: "FAuthFennecInstall",
  inheritAttrs: false,
  setup() {
    const {
      smAndDown
    } = useDisplay();
    const {
      t
    } = useLocale();
    const handleInstall = () => {
      const url = "";
      // const url =
      //   getBrowser() === "firefox"
      //     ? "https://addons.mozilla.org/firefox/addon/fox_fennec"
      //     : "https://chrome.google.com/webstore/detail/fennec/eincngenkhohbbfpkohipekcmnkfamjp";
      window.open(url);
    };
    return () => {
      let _slot;
      return _createVNode("div", {
        "class": ["f-auth-fennec", "f-auth-step2", {
          "f-auth-step2--small": smAndDown.value
        }]
      }, [_createVNode("div", {
        "class": "f-auth-step2__left"
      }, null), _createVNode("div", {
        "class": "f-auth-step2__right"
      }, [_createVNode("div", {
        "class": "f-auth-step2__title"
      }, [t("$vuetify.uikit.fennec_not_installed")]), _createVNode("div", {
        "class": "f-auth-step2__subtitle"
      }, [t("$vuetify.uikit.fennec_introduction")]), _createVNode(FButton, {
        "color": "greyscale_1",
        "onClick": handleInstall
      }, _isSlot(_slot = t("$vuetify.uikit.install")) ? _slot : {
        default: () => [_slot]
      })])]);
    };
  }
});