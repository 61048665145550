import { createVNode as _createVNode } from "vue";
export var FIconCheck4P = function FIconCheck4P() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "16",
    "height": "16",
    "viewBox": "0 0 16 16",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "d": "M13 4.5L6.25 11.25L3.5 7.75",
    "stroke": "currentColor",
    "stroke-width": "1.5",
    "stroke-linecap": "round",
    "stroke-linejoin": "round"
  }, null)]);
};