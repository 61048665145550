import { createVNode as _createVNode } from "vue";
export var FIconWarningFill = function FIconWarningFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "24",
    "height": "24",
    "viewBox": "0 0 24 24",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M14.0878 2.94604C13.1586 1.34111 10.8414 1.34111 9.91225 2.94604L0.828253 18.6366C-0.102846 20.2448 1.05766 22.2577 2.91601 22.2577H21.084C22.9423 22.2577 24.1028 20.2448 23.1717 18.6366L14.0878 2.94604ZM12 8.98948C12.6662 8.98948 13.2062 9.52951 13.2062 10.1957V14.4174C13.2062 15.0835 12.6662 15.6236 12 15.6236C11.3338 15.6236 10.7938 15.0835 10.7938 14.4174V10.1957C10.7938 9.52951 11.3338 8.98948 12 8.98948ZM12 16.7634C12.6662 16.7634 13.2062 17.3034 13.2062 17.9696V18.036C13.2062 18.7021 12.6662 19.2422 12 19.2422C11.3338 19.2422 10.7938 18.7021 10.7938 18.036V17.9696C10.7938 17.3034 11.3338 16.7634 12 16.7634Z",
    "fill": "currentColor"
  }, null)]);
};