import { createVNode as _createVNode } from "vue";
export var FIconHelp3PFill = function FIconHelp3PFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12ZM4.75 4.50001C4.75 4.26518 4.85223 4.08106 5.06438 3.9277C5.29419 3.76157 5.63942 3.64988 6.01766 3.64097C6.39697 3.63204 6.72809 3.72799 6.94195 3.87924C7.1295 4.01187 7.25 4.19719 7.25 4.50001C7.25 4.76741 7.17919 4.88178 7.12 4.95028C7.0369 5.04646 6.90532 5.13244 6.66166 5.25561C6.63403 5.26957 6.60292 5.28487 6.56907 5.30151C6.36558 5.40153 6.0634 5.55008 5.82799 5.7493C5.4941 6.03188 5.25 6.4382 5.25 7C5.25 7.41421 5.58579 7.75 6 7.75C6.41421 7.75 6.75 7.41421 6.75 7C6.75 6.93408 6.75587 6.92911 6.79672 6.89454L6.79701 6.89429C6.88017 6.82391 6.98674 6.77056 7.19482 6.6664L7.19483 6.6664C7.23805 6.64476 7.28566 6.62093 7.33834 6.5943C7.59468 6.46473 7.9631 6.26881 8.255 5.93099C8.57081 5.56549 8.75 5.09376 8.75 4.50001C8.75 3.68317 8.3705 3.0523 7.80805 2.65454C7.27191 2.2754 6.60303 2.12677 5.98234 2.14139C5.36058 2.15603 4.70581 2.33602 4.18562 2.71206C3.64777 3.10087 3.25 3.70938 3.25 4.50001C3.25 4.91422 3.58579 5.25001 4 5.25001C4.41421 5.25001 4.75 4.91422 4.75 4.50001ZM6.75 8.99999C6.75 8.58578 6.41421 8.24999 6 8.24999C5.58579 8.24999 5.25 8.58578 5.25 8.99999V9.05534C5.25 9.46956 5.58579 9.80534 6 9.80534C6.41421 9.80534 6.75 9.46956 6.75 9.05534V8.99999Z",
    "fill": "currentColor"
  }, null)]);
};