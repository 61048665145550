import { createVNode as _createVNode } from "vue";
export var FIconInfo4P = function FIconInfo4P() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true",
    "fill": "none"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M6.37991 4.33979C6.83837 4.33979 7.21002 3.96813 7.21002 3.50968C7.21002 3.05122 6.83837 2.67957 6.37991 2.67957C5.92146 2.67957 5.5498 3.05122 5.5498 3.50968C5.5498 3.96813 5.92146 4.33979 6.37991 4.33979ZM7.12634 9.00075C7.14514 8.9936 7.16422 8.98635 7.18358 8.97899L7.28366 8.59042C7.23657 8.61397 7.15414 8.64341 7.04228 8.67285C6.93043 8.70228 6.83034 8.71994 6.74203 8.71994C6.55364 8.71994 6.41823 8.69051 6.3417 8.63163C6.26516 8.56687 6.22395 8.45502 6.22395 8.29017C6.22395 8.21952 6.23572 8.12533 6.25927 7.99581C6.28282 7.86629 6.31226 7.74854 6.3417 7.64845L6.70671 6.39446C6.74792 6.2826 6.77147 6.15308 6.78324 6.01767C6.8009 5.88226 6.80679 5.78218 6.80679 5.72919C6.80679 5.47015 6.7126 5.25232 6.5242 5.08748C6.33581 4.92263 6.06499 4.84021 5.71175 4.84021C5.51159 4.84021 5.30553 4.87553 5.0877 4.94029C4.86987 5.00505 4.64026 5.08748 4.39888 5.18167L4.30469 5.57024C4.37039 5.54286 4.44627 5.52058 4.53708 5.49391C4.54391 5.4919 4.55083 5.48987 4.55784 5.48781C4.65793 5.45838 4.75212 5.4466 4.84632 5.4466C5.03471 5.4466 5.16423 5.47604 5.23488 5.5408C5.29964 5.60556 5.33497 5.71742 5.33497 5.87638C5.33497 5.96469 5.32319 6.06477 5.29964 6.17074C5.27609 6.27671 5.24666 6.39446 5.21722 6.51221L4.85221 7.7662C4.84769 7.78609 4.84316 7.80571 4.83871 7.82505C4.81414 7.93161 4.79152 8.02975 4.78156 8.11944C4.7639 8.22541 4.75801 8.3255 4.75801 8.42558C4.75801 8.68462 4.85809 8.89656 5.05238 9.0673C5.24666 9.23803 5.52336 9.32045 5.8766 9.32045C6.1062 9.32045 6.30637 9.28513 6.48299 9.23214C6.64799 9.18263 6.85924 9.10231 7.12634 9.00075Z",
    "fill": "currentColor"
  }, null)]);
};