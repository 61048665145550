import { createVNode as _createVNode } from "vue";
export var FIconClear3PFill = function FIconClear3PFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0C9.31371 0 12 2.68629 12 6ZM8.29791 8.29809C8.00502 8.59098 7.53014 8.59098 7.23725 8.29809L6.00001 7.06085L4.76275 8.2981C4.46986 8.591 3.99499 8.591 3.70209 8.2981C3.4092 8.00521 3.4092 7.53034 3.70209 7.23744L4.93953 6.00001L3.70209 4.76257C3.4092 4.46968 3.4092 3.9948 3.70209 3.70191C3.99499 3.40902 4.46986 3.40902 4.76275 3.70191L5.99999 4.93915L7.23725 3.7019C7.53014 3.409 8.00502 3.409 8.29791 3.7019C8.5908 3.99479 8.5908 4.46966 8.29791 4.76256L7.06047 5.99999L8.29791 7.23743C8.5908 7.53032 8.5908 8.0052 8.29791 8.29809Z",
    "fill": "currentColor"
  }, null)]);
};