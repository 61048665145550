import { createVNode as _createVNode } from "vue";
export var FIconInformation3PFill = function FIconInformation3PFill() {
  return _createVNode("svg", {
    "xmlns": "http://www.w3.org/2000/svg",
    "width": "12",
    "height": "12",
    "viewBox": "0 0 12 12",
    "aria-hidden": "true"
  }, [_createVNode("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    "d": "M6 12C9.31371 12 12 9.31372 12 6C12 2.68628 9.31371 0 6 0C2.68629 0 0 2.68628 0 6C0 9.31372 2.68629 12 6 12ZM7.04935 9.01447C6.98503 9.03891 6.92393 9.06296 6.86494 9.08618C6.53401 9.21643 6.26971 9.32043 5.87717 9.32043C4.88197 9.32043 4.66994 8.57065 4.85277 7.7662L5.21777 6.51221C5.24722 6.39447 5.27666 6.2767 5.3002 6.17075C5.42258 5.62009 5.16151 5.31516 4.65257 5.46484L4.63124 5.4711C4.57018 5.48901 4.51587 5.50497 4.46687 5.52185C4.44321 5.53 4.42078 5.53836 4.39941 5.54727L4.49361 5.15869C4.97636 4.97031 5.31198 4.84021 5.71231 4.84021C6.58118 4.84021 6.98637 5.63687 6.70728 6.39447C6.66292 6.54684 6.61516 6.69934 6.56728 6.8522C6.44905 7.22968 6.33008 7.60947 6.25983 7.99582C6.23628 8.12534 6.22452 8.21951 6.22452 8.29016C6.22452 8.75858 6.55685 8.80075 6.90807 8.70831C6.96468 8.69342 7.01375 8.67853 7.05453 8.6644C7.09431 8.6506 7.12619 8.63751 7.14944 8.62589L7.04935 9.01447ZM6.38089 4.33978C6.83936 4.33978 7.211 3.96814 7.211 3.50967C7.211 3.05121 6.83936 2.67957 6.38089 2.67957C5.92242 2.67957 5.55078 3.05121 5.55078 3.50967C5.55078 3.96814 5.92242 4.33978 6.38089 4.33978Z",
    "fill": "currentColor"
  }, null)]);
};