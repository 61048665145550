import { FIconChevronLeft, FIconChevronRight, FIconClose, FIconClear3PFill, FIconChevronDown4P, FIconConnect3PFill, FIconUpRight3PFill, FIconInformation3PFill, FIconWarningFill, FIconSearch, FIconHelp3PFill, FIconCheck4P, FIconInfo4P, FIconLink4P, FIconImport4PBold, FIconAlert4P, FIconLink4PBold, FIconArrowRight4P, FIconHorn4P, FIconArrowDown4PBold } from "@foxone/icons";
export const icons = {
  back: FIconChevronLeft,
  next: FIconChevronRight,
  close: FIconClose,
  clear: FIconClear3PFill,
  expand: FIconChevronDown4P,
  swap: FIconArrowDown4PBold,
  connect: FIconConnect3PFill,
  fill: FIconUpRight3PFill,
  help: FIconInformation3PFill,
  warning: FIconWarningFill,
  search: FIconSearch,
  question: FIconHelp3PFill,
  check: FIconCheck4P,
  info: FIconInfo4P,
  link: FIconLink4P,
  install: FIconImport4PBold,
  alert: FIconAlert4P,
  link_bold: FIconLink4PBold,
  horn: FIconHorn4P,
  arrow_right: FIconArrowRight4P
};